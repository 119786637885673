:root

body
  position: relative
  color: #fff
  background: #343541
.App
  height: 100vh
  overflow-x: hidden
*::-webkit-scrollbar
  width: 6px
  height: 6px
  border-radius: 80px
  overflow: hidden
*::-webkit-scrollbar-track
  background-color: #d7d7d7
  border-radius: 100px
*::-webkit-scrollbar-thumb
  border-radius: 80px
  box-shadow: inset 0 0 6px #000000
  border-radius: 100px
