.root
  .icon
    color: rgba(255, 255, 255, 0.9)
    border: solid 1px hsla(0,0%,100%,.2)
    width: 25px
    height: 25px
    border-radius: 50%
    min-width: 25px
    display: inline-flex
    padding: 0
    margin: 3px
    align-items: center
    justify-content: center
    svg
      width: 0.8em
      height: 0.8em
