
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')

$breakpoint-md: 768px
.root
  width: 100%
  height: 100%
  overflow-x: hidden
  position: relative
  left: 0
  transition: all ease .3s
  margin: 0
  padding: 0
  color: rgba(255,255,255,0.9)
  font-family: "Roboto","Helvetica","Arial",sans-serif
  background: #343541
  @media (max-width: $breakpoint-md)
    min-height: 100vh
  h1
    text-align: center
    margin-bottom: 20px

  .container
    background: #343541
    margin: 0 auto
    display: flex
    padding-left: 260px
    @media (max-width: $breakpoint-md)
      padding-top: 50px
      padding-bottom: 141px
      padding-left: 0
    a
      color: rgba(255,255,255,0.9)
      text-decoration: none
    .menu
      width: 260px
      height: 100vh
      background: #202123
      padding: 7px
      transition: all ease .3s
      position: fixed
      left: 0
      top: 0
      z-index: 99990
      @media (max-width: $breakpoint-md)
        left: -260px
        position: fixed
        top: 0
      .title
        text-align: center
        font-size: 1rem
        padding: 3px 0
        color: #fff
        margin-bottom: 0.5rem

      .top
        height: calc(100vh - 130px)
        overflow-y: auto
      .bottom
        padding: 7px
        position: absolute
        bottom: 0
        border-top: solid 1px hsla(0,0%,100%,.2)
        left: 0
        right: 0

      .itemTop button
        border: solid 1px hsla(0,0%,100%,.2)

      .item svg
        display: inline-block
        margin-right: 5px
        vertical-align: middle
        font-size: 20px
        margin-top: -3px

      .item button
        display: block
        text-align: left
        padding: 12px 15px
        border-radius: 5px
        margin-bottom: 5px
        transition: all ease .3s
        width: 100%
        color: #fff
        border-left: solid transparent 2px
        &:hover
          background-color: rgb(52,53,65)
      .item button.active
        background-color: rgba(255, 255, 255, 0.2)
        border-left: solid #ffffff 2px

      .itemChild button svg
        font-style: normal
        font-variant: normal
        line-height: 1
        text-rendering: auto
        margin-right: 5px
        display: inline-block
  .content
    width: 100%
    background: #343541
    height: 100vh
    position: relative
    transition: all ease .3s
    padding: 0px
    @media (max-width: $breakpoint-md)
      width: 100%
      height: calc(100% - 50px - 141px)

  .chatBox
    position: fixed
    bottom: 0px
    background: #343541
    padding: 10px
    left: 260px
    right: 0
    @media (max-width: $breakpoint-md)
      left: 0

  .chatWrapper
    background: #343541
    margin: auto
  .messageList
    .messageBox
      max-width: 48rem
      display: flex
      align-items: flex-start
      margin: auto

    .message
      padding: 20px 15px
      border-color: rgba(32,33,35,.5)
    &.messageOwner
      background: #444654
    & .messageSender
      width: 35px
      height: 35px
      background: #058acf
      line-height: 35px
      text-align: center
      display: block
      font-size: 10px
      min-width: 35px
      border-radius: 5px
    .messageOwner .messageSender
      background: #0f8d00

    & .messageContent
      display: block
      padding: 9px 20px
      line-height: 1.4
    & .messageContent a
      color: #b2e5ff
      text-decoration: none
      text-decoration: underline
      text-underline-position: under
      &:hover
        color: #60c8fd
  .messageInput
    max-width: 48rem
    position: relative
    margin: 15px auto 0

    textarea
      padding: 10px
      border-radius: 5px
      border: none
      width: 100%
      color: #fff
      border-color: rgba(32,33,35,.5)
      background-color: rgb(64,65,79)
      box-shadow: 0 0 transparent,0 0 transparent,0 0 15px rgba(0,0,0,.1)
      max-height: 200px
      line-height: 1.6
      overflow-y: hidden
      font-size: 1.2rem
      max-width: 100%
      padding-right: 60px
      &:focus
        outline: 0

    .button
      min-width: inherit
      position: absolute
      width: 50px
      height: 50px
      right: 5px
      top: 0
      bottom: 0
      margin: auto
      color: #a5a5a5
      font-size: 1.2rem
      background: rgb(155 155 155 / 25%)
      z-index: 5
      &:hover
        background-color: #0050c8
      &:disabled
        cursor: no-drop
        background: transparent
  .buttonGroup
    margin-top: 10px
    opacity: 0
    transition: all ease 0.3s
    .chatBtn
      text-align: left
      transition: all ease 0.3s
      background: transparent
      margin: auto
      cursor: pointer
      color: rgba(255, 255, 255, 0.9)
      padding: 0
      min-width: inherit
      width: 25px
      height: 25px
      border: solid 1px hsla(0,0%,100%,.2)
      &:hover
        background-color: rgb(48 49 60)
      svg
        width: 0.8em
        height: 0.8em

  .messageList .messageBox:hover .buttonGroup,
  .messageList >div:nth-last-child(-n+2) .buttonGroup
    opacity: 1
  .messageList.action .refreshBtn
    display: none
  .mobile
    display: none
    @media (max-width: $breakpoint-md)
      display: block

  .loading:after
    content: '.'
    animation: spin 1s linear infinite
  @keyframes spin
    0%
      content: '.'
    50%
      content: '..'
    100%
      content: '...'
  .hidden
    display: none
  // 手機版header
  .header
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 50px
    display: flex
    justify-content: space-between
    z-index: 9999
    align-items: center
    border-bottom: solid 1px hsla(0,0%,100%,.2)
    background: #343541
    transition: all ease .3s
    .title
      width: calc(100% - 100px)
      text-align: center
    svg
      width: 30px
      height: 30px
      display: flex
      align-items: center
      justify-content: center
      font-size: 1.2rem
      color: #ddd
      transition: all ease .3s
      &:hover
        color: #fefefe
.root.activeMenu .header
  left: 260px
.root.activeMenu .menu
  left: 0
.root.activeMenu
  left: 260px
.root .black
  content: ''
  position: absolute
  display: block
  top: 0
  right: 0
  left: 0
  bottom: 0
  background: rgba(0, 0, 0, 0.6)!important
  z-index: 99990
.messageListRef
  height: 123px
.sources
  font-size: 12px
  display: inline-block
  margin-left: 5px
